import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import { SubLink } from 'containers'
import { connect } from 'react-redux'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Chip,
  Box,
  Tooltip,
  Divider,
} from '@material-ui/core'
import { timeSince, formatCostumDateTimeForUI } from 'utils/formatDate'
import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import PropTypes from 'prop-types'
import LoadingSpinner from './loader/LoadingSpinner'
import {
  EVENT_DASHBOARD_ROUTE,
  MATCH_DASHBOARD_ROUTE,
  TRAINING_DASHBOARD_ROUTE,
} from 'constants/routes'
import { TRAININGS_GAME } from 'constants/calender'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 204,
    paddingTop: theme.spacing(0),
    '& .MuiListItem-root:hover': {
      background: '#f3f3f3',
    },
    '& .MuiListItem-root': {
      padding: theme.spacing(0),
      '& .MuiListItemText-root': {
        padding: theme.spacing(1),
      },
      '& .MuiListItemText-primary': {
        '& .MuiBox-root': {
          display: 'flex',
          alignItems: 'start',
          fontWeight: 500,
        },
        '& .MuiChip-root': {
          fontSize: '0.875rem',
          padding: theme.spacing(0, 1),
        },
        '& .MuiTypography-root': {
          color: theme.palette.card.color,
          [theme.breakpoints.only('md')]: {
            fontSize: theme.typography.pxToRem(13),
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(12),
          },
        },
      },
      '& .MuiListItemText-secondary': {
        fontSize: theme.typography.pxToRem(13),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(12),
        },
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.8),
      },
    },
  },
}))

const Ticker = ({ loader, timeZone }) => {
  const classes = useStyles()
  const fromPathname = ''

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [tickers, setTickers] = useState([])

  const hasEditMatchEnabled = useSubscriptionFeature('edit_match_enabled')
  const hasEditGeneralEventEnabled = useSubscriptionFeature(
    'edit_general_event_enabled'
  )

  const getEventPageUrl = (item) => {
    if (item.type.startsWith(TRAININGS_GAME.match)) {
      return MATCH_DASHBOARD_ROUTE.populateURLParams({
        matchId: item.match_uuid,
      })
    }
    if (item.type.startsWith(TRAININGS_GAME.training)) {
      return TRAINING_DASHBOARD_ROUTE.populateURLParams({
        trainingUuid: item.training_uuid,
      })
    }
    if (item.type.startsWith(TRAININGS_GAME.general)) {
      return EVENT_DASHBOARD_ROUTE.populateURLParams({
        eventUuid: item.event_uuid,
      })
    }
  }
  useEffect(() => {
    setLoading(true)
    loader()
      .then((res) => {
        setTickers(res.response.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
        setLoading(false)
      })
  }, [])

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            <Translate id="ticker.ticker" />
          </Typography>
        }
      />

      <CardContent className={classes.root}>
        {isLoading && <LoadingSpinner />}
        {error && <div> Something went wrong! </div>}
        {tickers &&
          tickers.map((item) => (
            <List key={item.id} disablePadding>
              <ListItem key={item.id}>
                <ListItemText
                  id={item.id}
                  primary={
                    <>
                      {hasEditMatchEnabled || hasEditGeneralEventEnabled ? (
                        <Box
                          component={SubLink}
                          to={getEventPageUrl(item)}
                          fromPathname={fromPathname}
                          key={item.id}
                        >
                          <Tooltip
                            title={formatCostumDateTimeForUI(item.created_at)}
                            arrow
                          >
                            <Typography style={{ marginRight: 10 }}>
                              <Chip
                                label={timeSince(
                                  new Date(item.created_at),
                                  timeZone
                                )}
                                size="small"
                                disabled
                                color="primary"
                              />
                            </Typography>
                          </Tooltip>
                          <Typography variant="body1" component="p">
                            {item.msg}
                          </Typography>
                        </Box>
                      ) : (
                        <Box key={item.id}>
                          <Tooltip
                            title={formatCostumDateTimeForUI(item.created_at)}
                            arrow
                          >
                            <Typography style={{ marginRight: 10 }}>
                              <Chip
                                label={timeSince(
                                  new Date(item.created_at),
                                  timeZone
                                )}
                                size="small"
                                disabled
                                color="primary"
                              />
                            </Typography>
                          </Tooltip>
                          <Typography variant="body1" component="p">
                            {item.msg}
                          </Typography>
                        </Box>
                      )}
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </List>
          ))}
      </CardContent>
    </Card>
  )
}

Ticker.propTypes = {
  loader: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
}

export default connect(({ auth }) => ({
  timeZone: auth.user.time_zone,
}))(Ticker)
