import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { staff as staffModule } from 'redux/modules'
import { useParams, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import Form from './components/Form'

const StaffFormContainer = ({
  onCreate,
  onUpdate,
  handleClose,
  initialValues,
  currentClubId,
}) => {
  const { teamId } = useParams()

  const handleCreate = (data) => {
    onCreate(teamId, data).then(() => {
      handleClose()
    })
  }

  const handleUpdate = (data) => {
    onUpdate(data.id, data).then(() => {
      handleClose()
    })
  }

  const staffId = initialValues?.id

  return (
    <Form
      onSubmit={staffId ? handleUpdate : handleCreate}
      currentClubId={currentClubId}
      initialValues={staffId ? initialValues : {}}
      handleClose={handleClose}
    />
  )
}

StaffFormContainer.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  currentClubId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ clubs }) => ({
      currentClubId: clubs.current.id,
    }),
    {
      onCreate: staffModule.createStaff,
      onUpdate: staffModule.updateStaff,
    }
  )
)(StaffFormContainer)
