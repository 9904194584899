import React, { useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Card,
  Grid,
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Carousel } from 'components'
import { SubLink } from 'containers'
import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLES_CATEGORIES_VIEW } from 'constants/routes'
import hideArticleCatArr from 'constants/article'

const MuiCard = withStyles((theme) => ({
  root: {
    width: 257,
    border: '1px solid #e8e8e8',
    margin: theme.spacing(0, 3, 0, 0),
    '& .MuiCardContent-root': {
      padding: theme.spacing(1, 2, 0.5),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)

const useStyles = makeStyles({
  media: {
    height: 140,
    backgroundPosition: 'top',
  },
  title: {
    flex: '1 1 0',
  },
})

const Categories = ({ categories }) => {
  const classes = useStyles()

  const hasHideMyClubCategoryEnabled = useSubscriptionFeature(
    'hide_my_club_category_enabled'
  )

  return (
    <Carousel>
      {categories.map((category) =>
        hasHideMyClubCategoryEnabled &&
        hideArticleCatArr.indexOf(category.title.toLowerCase()) !== -1 ? (
          ''
        ) : (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={category.id}>
              <MuiCard>
                <CardActionArea
                  component={SubLink}
                  to={`${ARTICLES_CATEGORIES_VIEW}/${category.id}`}
                >
                  <CardMedia
                    className={classes.media}
                    image={
                      category.media.length ? category.media[0].full_url : ''
                    }
                  />
                </CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h4" component="h4">
                    {category.title}
                  </Typography>
                </CardContent>
              </MuiCard>
            </Grid>
          </>
        )
      )}
    </Carousel>
  )
}

Categories.defaultProps = {
  categories: [],
}

Categories.propTypes = {
  categories: PROP_TYPES.arrayOfObjects,
}

// eslint-disable-next-line react/no-multi-comp
const FilterByCategories = ({ articlesCategories }) => {
  const firstLevelCategories = useMemo(
    () => articlesCategories.filter((category) => category.parent_id === null),
    [articlesCategories]
  )

  return (
    <>
      <Box>
        <Categories
          categories={firstLevelCategories}
          articlesCategories={articlesCategories}
        />
      </Box>
    </>
  )
}

FilterByCategories.defaultProps = {
  articlesCategories: [],
}

FilterByCategories.propTypes = {
  articlesCategories: PROP_TYPES.arrayOfObjects,
}

export default compose(
  withRouter,
  connect(
    ({
      articles: {
        current: { articlesCategories },
      },
    }) => ({
      articlesCategories,
    })
  )
)(FilterByCategories)
