import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab,
  Divider,
  Typography,
  Button,
  IconButton,
  Dialog,
} from '@material-ui/core'
import NavigationPrompt from 'react-router-navigation-prompt'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import TeaserHeader from 'components/Teaser/TeaserHeader'
import { PlayerStatisticsWidget, SkillRatingsWidget } from 'containers'
import { PerformanceWidget } from 'components'
import CloseIcon from '@material-ui/icons/Close'
import { players as playersModule } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'

import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import {
  PlayerFitnessMedical,
  PlayerDevelopmentTracking,
  Sequences,
  MatchDayJournal,
  TopBar,
  PlayerSummary,
  PlayerDetails,
} from './components'
import useEditablePlayer from './useEditablePlayerHook'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
})
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
  },
  PerformanceWrap: {
    '& .performance-chart': {
      width: '100% !important',
    },
    '& .performance-widget-style': {
      paddingBottom: `${theme.spacing(0)} !important`,
      marginBottom: `${theme.spacing(1)} !important`,
      height: '100% !important',
    },
  },
  wBoardButton: {
    display: 'block',
    margin: `${theme.spacing(3)} auto`,
    width: theme.spacing(14),
  },
}))

const PlayerDashboardContainer = ({
  playerId,
  history,
  match,
  match: {
    params: { seasonId },
  },
  fromUrl,
  currentPlayer,
  updatePlayer,
  updatePlayerAvatar,
  deletePlayer,
  fetchTeamPlayer,
  language,
  timezone,
}) => {
  const [editablePlayer, changeEditablePlayer] = useEditablePlayer(
    currentPlayer,
    {}
  )
  const {
    strengths,
    weaknesses,
    development_tracking: developmentTracking,
  } = editablePlayer

  const [anyChange, setAnyChange] = useState(false)

  const hasShowTeasorHeaderEnabled = useSubscriptionFeature(
    'show_teasor_header_enabled'
  )
  const hasPlayerMainStatsEnabled = useSubscriptionFeature(
    'player_main_stats_enabled'
  )
  const hasPlayerMatchPerformanceEnabled = useSubscriptionFeature(
    'player_match_performance_enabled'
  )
  const hasPlayerFitnessAndMedicalEnabled = useSubscriptionFeature(
    'player_fitness_and_medical_enabled'
  )
  const hasPlayerDevelopmentTrackingEnabled = useSubscriptionFeature(
    'player_development_tracking_enabled'
  )
  const hasVideoSectionEnabled = useSubscriptionFeature('video_enabled')
  const hasPlayerMatchDayJournalEnabled = useSubscriptionFeature(
    'player_match_date_journal_enabled'
  )

  const onPlayerEdit = (fieldName, newValue) => {
    if (fieldName in editablePlayer) {
      changeEditablePlayer({ ...editablePlayer, [fieldName]: newValue })
    }
    setAnyChange(true)
  }

  const handleSave = () => {
    setAnyChange(false)
    const editablePlayerTemp = {
      ...editablePlayer,
    }

    const response = updatePlayer(playerId, editablePlayerTemp)
    response.then(() => {
      setAnyChange(false)
    })

    return response
  }

  const handleShareClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const shareUrl = `${process.env.REACT_APP_API_URL}/players/${currentPlayer.uuid}/share?language=${language}&time_zone=${timezone}&season=${seasonId}`
    window.open(shareUrl, '_blank')
  }

  const linkBack = fromUrl || createSubUrl(match, TEAM_DASHBOARD_ROUTE)

  const handleDelete = () =>
    deletePlayer(+playerId).then(() => {
      history.push(linkBack)
    })
  const matchesPerformance =
    currentPlayer.statistics.matches_performance.filter((p) => p.rating > 0)

  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const classes = useStyles()

  

  return (
    <>
      <NavigationPrompt when={anyChange}>
        {({ onConfirm, onCancel }) => {
          return (
            <>
              <Dialog aria-labelledby="dialog-player" open>
                <DialogTitle id="dialog-player" onClose={onCancel}>
                  <Translate id="dialog.unsaved.title" />
                </DialogTitle>
                <DialogContent dividers>
                  <Typography variant="p" component="p">
                    <Translate id="dialog.unsaved.content-player" />
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleSave().then(onCancel)}
                    size="large"
                    variant="contained"
                    className={classes.wBoardButton}
                    color="primary"
                  >
                    <Translate id="dialog.unsaved.save-player" />
                  </Button>
                  <Button onClick={onConfirm} size="large">
                    <Translate id="dialog.unsaved.leave" />
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }}
      </NavigationPrompt>

      {hasShowTeasorHeaderEnabled && (
        <Grid item xs={12} md={12} lg={12}>
          <TeaserHeader />
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <TopBar
            handleSave={handleSave}
            onShare={handleShareClick}
            id={playerId}
            deletePlayer={handleDelete}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={3}>
          <PlayerSummary
            player={{ ...currentPlayer }}
            handleDelete={handleDelete}
            updatePlayer={updatePlayer}
            updatePlayerAvatar={updatePlayerAvatar}
            handleSave={handleSave}
            changeEditablePlayer={changeEditablePlayer}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <PlayerDetails
            player={{ ...currentPlayer }}
            statistics={currentPlayer.statistics}
          />
        </Grid>
        {hasPlayerMainStatsEnabled && (
          <Grid item xs={12} md={12} lg={6}>
            <Card className={classes.card}>
              <Translate>
                {({ translate }) => (
                  <>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label={translate('team.main-stats')} />
                    </Tabs>
                    <Divider />
                  </>
                )}
              </Translate>
              <CardContent>
                {value === 0 && (
                  <PlayerStatisticsWidget
                    statistics={currentPlayer.statistics}
                    player={{ ...currentPlayer }}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={6}>
          <SkillRatingsWidget skills={currentPlayer.skills} />
        </Grid>
        {hasPlayerMatchPerformanceEnabled && (
          <Grid item xs={12} md={12} lg={6} className={classes.PerformanceWrap}>
            <Translate>
              {({ translate }) => (
                <PerformanceWidget
                  title={translate('team.player-match-performance')}
                  match={currentPlayer.statistics}
                  data={matchesPerformance}
                />
              )}
            </Translate>
          </Grid>
        )}
        {hasPlayerFitnessAndMedicalEnabled && (
          <Grid item xs={12} md={12} lg={6}>
            <PlayerFitnessMedical
              onChange={onPlayerEdit}
              fitnessNmedicalRecords={currentPlayer.fitnessNmedicalRecords}
              physicalAge={currentPlayer.physique_age}
              physicalHeight={currentPlayer.physique_height}
              physicalWeight={currentPlayer.physique_weight}
              playerId={currentPlayer.id}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={12}
          lg={hasPlayerDevelopmentTrackingEnabled ? 6 : 12}
        >
          <PlayerDevelopmentTracking
            onChange={onPlayerEdit}
            fetchTeamPlayer={fetchTeamPlayer}
            player={{ ...currentPlayer }}
            handleSave={handleSave}
            {...{
              development_tracking: developmentTracking,
              strengths,
              weaknesses,
            }}
          />
        </Grid>

        {hasVideoSectionEnabled && (
          <Grid item xs={12} md={12} lg={12}>
            <Sequences sequences={currentPlayer.sequences} />
          </Grid>
        )}

        {hasPlayerMatchDayJournalEnabled && (
          <Grid item xs={12} md={12} lg={12}>
            <MatchDayJournal journal={currentPlayer.journal} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

PlayerDashboardContainer.defaultProps = {
  fromUrl: '',
  fetchTeamPlayer: () => 0,
}

PlayerDashboardContainer.propTypes = {
  playerId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  fromUrl: PropTypes.string,
  currentPlayer: PropTypes.shape().isRequired,
  updatePlayer: PropTypes.func.isRequired,
  deletePlayer: PropTypes.func.isRequired,
  updatePlayerAvatar: PropTypes.func.isRequired,
  fetchTeamPlayer: PropTypes.func,
  timezone: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
}

export default connect(
  ({
    team,
    router: {
      location: { fromUrl },
    },
    players,
    auth,
  }) => ({
    fromUrl,
    team,
    currentPlayer: players.current,
    timezone: auth.user.time_zone,
    language: auth.user.language,
  }),
  {
    updatePlayer: playersModule.updatePlayer,
    deletePlayer: playersModule.deletePlayer,
    updatePlayerAvatar: playersModule.updatePlayerAvatar,
    fetchTeamPlayer: playersModule.fetchTeamPlayer,
  }
)(PlayerDashboardContainer)
