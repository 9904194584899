import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import TeamCaptainLabel from 'assets/images/captain.svg'
import GoalkeeperLabel from 'assets/images/Goalkeeper.svg'
import RightfootedLabel from 'assets/images/right-footed.svg'
import PlayerStatusToggle from 'containers/PausePlayer'
import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import { PLAYER_POSITIONS } from 'constants/player'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    position: 'relative',
    overflow: 'visible',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > .MuiListItem-root': {
      width: '50%',
      paddingLeft: theme.spacing(0.2),
      paddingRight: theme.spacing(0.2),
    },
  },
  captainLabel: {
    height: '100%',
    position: 'absolute',
    right: theme.spacing(-1.2),
    top: theme.spacing(-1.1),
  },
  totalPlayerTime: {
    paddingLeft: theme.spacing(0.9),
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
}))

const PlayerDetails = ({ player, statistics }) => {
  const classes = useStyles()

  const hasPlayerTrainingAttendanceEnabled = useSubscriptionFeature(
    'player_training_attendance_enabled'
  )

  return (
    <Card className={classes.card}>
      {(player.captain === 1 || player.captain === true) && (
        <Box className={classes.captainLabel}>
          <img src={TeamCaptainLabel} alt={TeamCaptainLabel} />
        </Box>
      )}
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            <Translate id="player.player-info" />
          </Typography>
        }
      />
      <CardContent>
        <List component="ul" className={classes.list}>
          <ListItem>
            <PlayerStatusToggle player={player} showText />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={GoalkeeperLabel} alt={GoalkeeperLabel} />
            </ListItemIcon>
            <ListItemText
              primary={
                PLAYER_POSITIONS.find((p) => p.value === player.position).title
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src={RightfootedLabel} alt={RightfootedLabel} />
            </ListItemIcon>
            <ListItemText
              primary={
                player.foot === 'r' ? (
                  <Translate id="player.r-foot" />
                ) : (
                  <Translate id="player.l-foot" />
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon className={classes.TotalPlayerTime}>
              {player.number}
            </ListItemIcon>
            <Translate>
              {({ translate }) => (
                <ListItemText primary={translate('form.number')} />
              )}
            </Translate>
          </ListItem>
          {hasPlayerTrainingAttendanceEnabled && (
            <ListItem className="w-100">
              <ListItemIcon className="h5 font-weight-bold">
                {Number.isNaN(
                  statistics.trainings_attended / statistics.trainings_total
                )
                  ? '0%'
                  : `${Math.round(
                      (statistics.trainings_attended /
                        statistics.trainings_total) *
                        100
                    )}%`}
              </ListItemIcon>
              <ListItemText
                primary={<Translate id="player.training-match-attendence" />}
              />
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  )
}

PlayerDetails.propTypes = {
  player: PropTypes.shape().isRequired,
  statistics: PropTypes.shape().isRequired,
}

export default PlayerDetails
