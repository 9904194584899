import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import InviteMembers from './components/InviteMembers'
import useStyles from './styles'
import AllMembers from './components/AllMembers'

const UserManagement = () => {
  const classes = useStyles()

  return (
    <>
      <Box component={Paper} className={classes.root}>
        <Box style={{ marginBottom: '20px' }}>
          <Typography variant="h2">
            <Translate id="userRoles.team-access" />
          </Typography>
        </Box>
        <InviteMembers />
        <AllMembers />
      </Box>
    </>
  )
}

export default UserManagement
