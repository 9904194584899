import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { auth as authModule } from 'redux/modules'
import MDInput from 'components/material/MDInput'
import {
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  InputAdornment,
  MenuItem,
} from '@material-ui/core'
import {
  handleTextField,
  checkError,
  filterDataWithoutError,
  checkTextFieldError,
} from 'utils/validate'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SubLink, InnrHead } from 'containers'
import { setLocale } from 'utils/localstorage'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Translate, withLocalize } from 'react-localize-redux'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PersonIcon from '@material-ui/icons/Person'
import ReactCountryFlag from 'react-country-flag'
import { SelectedMenu, AvatarUploader } from 'components'
import AvatarIcon from 'assets/images/avatar.png'
import { currentDateFormat, DATE_FORMAT } from 'utils/formatDate'
import { setUserlaneLanguage } from 'utils/userlane'
import timezonelist from 'constants/timezonelist'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 2),
  },
  Avatar: {
    height: 200,
    width: 200,
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    backgroundColor: '#F4F6F8',
    padding: theme.spacing(2),
  },
  BoxCard: {
    position: 'relative',
    height: 200,
    width: 200,
    margin: theme.spacing(3, 'auto'),
  },
  uploadImg: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    margin: theme.spacing(0, 'auto'),
  },
  newImgUpload: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const userLanguages = [
  { name: 'English', value: 'en' },
  { name: 'German', value: 'de' },
  { name: 'Spanish', value: 'es' },
  { name: 'Italian',  value: 'it' },
  { name: 'Polish', value: 'pl' },
  { name: 'Japan',  value: 'jp' },
]

const UserDashboard = ({
  user,
  match,
  updateUser,
  teamId,
  setActiveLanguage,
}) => {
  const {
    params: { seasonId },
  } = match

  const [userData, setUserData] = useState({
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    language: '',
    avatar: '',
    time_zone: '',
    datetime_format: '',
  })

  const classes = useStyles()

  const [images, setImages] = useState([])
  const maxNumber = 1
  const maxMbFileSize = 5 * 1024 * 1024 // 5Mb

  const submit = () => {
    const isError = checkError(userData)
    const isErrorForFields = checkTextFieldError(userData, [
      'first_name',
      'last_name',
    ])

    if (isError) {
      setUserData({ ...userData, [isError]: true })

      return
    }

    if (isErrorForFields) {
      setUserData({ ...userData, [isErrorForFields]: true })

      return
    }
    userData.avatar = images.length > 0 ? images[0].file : null

    updateUser(filterDataWithoutError(userData)).then((res) => {
      const userLanguage = res.response.data.language

      // Trigger userlane method to change the language of userlane assistant
      setUserlaneLanguage(userLanguage)

      setActiveLanguage(userLanguage)
      setLocale(userLanguage)
    })
  }

  const handleImageChange = (imageList) => setImages(imageList)

  useEffect(() => {
    setUserData(user)
  }, [user])

  const handleLanguageChange = (event) => {
    setUserData({ ...userData, language: event.target.value })
  }
  const handleTZChange = (event) => {
    setUserData({ ...userData, time_zone: event.target.value })
  }
  const handleDateFormatChange = (event) => {
    setUserData({ ...userData, datetime_format: event.target.value })
    currentDateFormat()
  }

  return (
    <>
      <InnrHead
        title={<Translate id="home.hello" />}
        label={userData.first_name}
      >
        <Button
          component={SubLink}
          to={`/team/${+teamId}/season/${+seasonId}`}
          isFullPath
          className={classes.button}
          variant="outlined"
          color="primary"
        >
          <Translate id="button.cancel" />
        </Button>
        <Button variant="contained" color="primary" onClick={submit}>
          <Translate id="button.save" />
        </Button>
      </InnrHead>
      <Paper component={Box} mt={2} p={3} elevation={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <AvatarUploader
              images={images}
              srcUrl={userData.avatar ? userData.avatar.full_url : AvatarIcon}
              onChange={handleImageChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" component={Box} pb={2}>
              <Translate id="player.general-info" />
            </Typography>

            <MDInput
              fullWidth
              required
              id="first_name"
              label={<Translate id="signup.first_name" />}
              type="text"
              name="first_name"
              variant="outlined"
              value={userData.first_name}
              onChange={(e) => handleTextField(e, userData, setUserData)}
              error={userData.first_name_error}
              helperText={
                userData.first_name_error ? 'First name required' : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />

            <MDInput
              fullWidth
              required
              id="last_name"
              label={<Translate id="signup.last_name" />}
              type="text"
              name="last_name"
              variant="outlined"
              value={userData.last_name}
              error={userData.last_name_error}
              onChange={(e) => handleTextField(e, userData, setUserData)}
              helperText={userData.last_name_error ? 'Last name required' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />

            <MDInput
              fullWidth
              required
              id="email"
              label={<Translate id="player.email" />}
              type="email"
              name="email"
              variant="outlined"
              value={userData.email}
              onChange={(e) => handleTextField(e, userData, setUserData)}
              disabled
              error={userData.email_error}
              helperText={userData.email_error ? 'Email is required' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />

            <MDInput
              fullWidth
              id="phone"
              label={<Translate id="player.phone" />}
              type="tel"
              name="phone"
              variant="outlined"
              value={userData.phone}
              onChange={(e) => handleTextField(e, userData, setUserData)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneIphoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" component={Box} pb={2}>
              <Translate id="player.settings" />
            </Typography>

            <SelectedMenu
              id="language"
              value={userData.language}
              className={classes.formControl}
              onChange={handleLanguageChange}
              label={<Translate id="player.default-language" />}
            >
              {userLanguages.map((lang) => (
                <MenuItem key={lang.value} value={lang.value}>
                  <Box display="flex" alignItems="center">
                    <ReactCountryFlag countryCode={lang.value === 'en' ? 'US' : lang.value.toUpperCase() } svg style={{ marginRight: 8 }} />
                    {lang.name}
                  </Box>
                </MenuItem>
              ))}
            </SelectedMenu>

            <SelectedMenu
              id="time-zone"
              value={userData.time_zone}
              className={classes.formControl}
              onChange={handleTZChange}
              label={<Translate id="player.timezone" />}
            >
              {timezonelist.map((zone) => (
                <MenuItem value={zone} key={zone}>
                  {zone}
                </MenuItem>
              ))}
            </SelectedMenu>
            <SelectedMenu
              id="datetime-format"
              value={userData.datetime_format}
              className={classes.formControl}
              onChange={handleDateFormatChange}
              options={DATE_FORMAT}
              label={<Translate id="player.dateformat" />}
            >
              {DATE_FORMAT.map((format) => (
                <MenuItem value={format} key={format}>
                  {format}
                </MenuItem>
              ))}
            </SelectedMenu>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

UserDashboard.propTypes = {
  user: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  updateUser: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  withLocalize,
  connect(
    ({ auth, team }) => ({
      user: auth.user,
      teamId: team.id,
    }),
    {
      updateUser: authModule.updateUser,
    }
  )
)(UserDashboard)
