import React from 'react'
import { withRouter } from 'react-router-dom'
import SubLink from 'containers/SubLink'
import { ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core'
import urlConstructor from 'utils/urlConstructor'
import { compose } from 'redux'
import { withLocalize } from 'react-localize-redux'
import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import PropTypes from 'prop-types'
import { List } from './styledComponents'
import menu from './constants/menu'

const Sidebar = ({
  match: {
    params: { teamId, seasonId },
  },
  location: { pathname },
  onClose,
}) => {
  const mainPagePathname = urlConstructor(teamId, seasonId)

  return (
    <>
      <List component="nav">
        {menu.map(
          ({
            to,
            title,
            icon,
            teaser,
            featureKey,
            isFullPath,
            rootPath,
            target,
            id,
            showFeatureInSubscriptionKey,
          }) => {
            return (
              <Box
                style={{ position: 'relative', overflow: 'hidden' }}
                id={id}
                key={to}
                hidden={
                  showFeatureInSubscriptionKey &&
                  !useSubscriptionFeature(showFeatureInSubscriptionKey)
                }
              >
                {featureKey && !useSubscriptionFeature(featureKey) && teaser}
                <ListItem
                  to={to}
                  button
                  component={SubLink}
                  isFullPath={isFullPath}
                  target={target}
                  isActive={!rootPath && pathname === mainPagePathname}
                  className={
                    (!rootPath && pathname === mainPagePathname) ||
                    (rootPath && pathname.includes(mainPagePathname + rootPath))
                      ? 'selected'
                      : ''
                  }
                >
                  <ListItemIcon onClick={onClose}>{icon}</ListItemIcon>
                  <ListItemText
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                    onClick={onClose}
                    primary={title}
                  />
                </ListItem>
              </Box>
            )
          }
        )}
      </List>
    </>
  )
}

Sidebar.defaultProps = {}

Sidebar.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
}

export default compose(withLocalize, withRouter)(Sidebar)
