import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Popover, Select, Box, Typography } from '@material-ui/core'
import { PLAYER_POSITIONS } from 'constants/player'

const Overlay = ({ playerPosition, handleChangePosition, handleDelete }) => (
  <Box
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
  >
    <Select
      value={playerPosition}
      onChange={handleChangePosition}
      options={PLAYER_POSITIONS}
    />
    <Button onClick={handleDelete}>Delete</Button>
  </Box>
)

const NewForLineup = ({
  number,
  playerPosition,
  onChangePosition,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    handlePopoverClose()
    onDelete()
  }

  const handleChangePosition = (newPosition) => {
    handlePopoverClose()
    onChangePosition(newPosition)
  }

  return (
    <Box
      display="flex"
      alignTiems="center"
      flexDirection="column"
      p={2}
      border={1}
      borderRadius={8}
    >
      <Typography variant="h6" component="div" mt={2}>
        {number}
      </Typography>

      <Button variant="contained" color="primary" onClick={handlePopoverOpen}>
        Change
      </Button>

      <Popover
        id="overlay-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <Overlay
            playerPosition={playerPosition}
            handleChangePosition={handleChangePosition}
            handleDelete={handleDelete}
          />
        </Box>
      </Popover>

      <Typography variant="body1" component="div" mt={2}>
        {playerPosition}
      </Typography>
    </Box>
  )
}

NewForLineup.propTypes = {
  number: PropTypes.number.isRequired,
  playerPosition: PropTypes.string.isRequired,
  onChangePosition: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default NewForLineup
