import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import MultiSelect from 'components/material/MultiSelect'
import { Input, SelectedMenu } from 'components'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  filterDataFromToday,
  formatCostumDateMonthNoForUI,
} from 'utils/formatDate'
import TeaserTagOverlay from 'components/Teaser/TeaserTagOverlay'
import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import { events as eventsModule } from '../../redux/modules'
import { PRO } from '../../constants/plans'
import AddEditQuestions from './AddEditQuestions'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'
import { TASK_GROUP_DUE_DATES } from 'constants/taskGroups'

const AddEditTrainingTaskGroup = ({
  taskGroup,
  isEditMode,
  playersList,
  handleInput,
  handlePlayerChange,
  handleCreate,
  handleUpdate,
  questions,
  handleQuestion,
  addNewQuestion,
  setQuestions,
  setTaskGroup,
  handleCheckboxSelectAllPlayers,
  selectAllPlayers,
  match: {
    params: { seasonId },
  },
  fetchCalendarEvents,
  dateFormat,
  eventId,
}) => {
  const [trainings, setTrainings] = useState([])
  const [selectedPlayers, setSelectedPlayer] = useState([])
  const [selectedTrainings, setSelectedTrainings] = useState([])
  const [selectAllEvents, setSelectAllEvents] = useState(eventId <= 0)

  const hasAddTaskQuestionsEnabled = useSubscriptionFeature(
    'add_task_questions_enabled'
  )

  const filterTrainings = (data) => {
    // Filter out dates in the past or falsey values
    return filterDataFromToday(data.map((d) => ({ ...d, date: d.event_date })))
  }
  const handleCheckboxSelectAllTrainings = (event) => {
    const { checked } = event.target

    setSelectAllEvents(checked)
    if (checked) {
      const filteredTrainings = filterTrainings(trainings)

      setTaskGroup({
        ...taskGroup,
        eventIds: [...new Set(filteredTrainings.map((p) => p.id))],
        [`error_eventIds`]: filteredTrainings.length <= 0,
      })
      setSelectedTrainings(filteredTrainings)
    }
  }
  const handleEventChange = (value) => {
    setTaskGroup({
      ...taskGroup,
      eventIds: [...new Set(value.map((p) => p.id))],
      [`error_eventIds`]: value.length <= 0,
    })
    setSelectedTrainings(value)
  }

  useEffect(() => {
    fetchCalendarEvents(seasonId).then((res) => {
      const response = res.response.data
      const tempTrainings = response.events
        .filter((s) => s.relation === 'training')
        .map((training) => ({
          id: training.id,
          label: `${formatCostumDateMonthNoForUI(
            training.date,
            dateFormat
          )} Focus: ${training.training_focus ? training.training_focus : ''}`,
          event_date: training.date,
        }))

      setTrainings(tempTrainings)

      const isSelectAllEvents =
        taskGroup && taskGroup?.eventIds?.length > 0
          ? taskGroup?.eventIds?.length === tempTrainings?.length
          : eventId <= 0

      setSelectAllEvents(isSelectAllEvents)
      if (isSelectAllEvents) {
        const filteredTrainings = filterTrainings(tempTrainings)

        setTaskGroup({
          ...taskGroup,
          eventIds: [...new Set(filteredTrainings.map((t) => +t.id))],
          [`error_eventIds`]: filteredTrainings.length <= 0,
        })
        setSelectedTrainings(filteredTrainings)
      } else {
        setSelectedTrainings(
          eventId > 0
            ? tempTrainings.filter((training) => eventId === training.id)
            : tempTrainings.filter((training) =>
                taskGroup?.eventIds?.includes(training.id)
              )
        )

        if (eventId > 0) {
          setTaskGroup({
            ...taskGroup,
            eventIds: [
              ...tempTrainings
                .filter(
                  (training) =>
                    taskGroup?.eventIds?.includes(training.id) ||
                    eventId === training.id
                )
                .map((p) => p.id),
            ],
          })
        }
      }
    })
  }, [])

  useEffect(() => {
    setSelectedPlayer(
      playersList.filter((player) => taskGroup?.modelIds?.includes(player.id))
    )
  }, [taskGroup.modelIds])

  return (
    <Grid container alignItems="center">
      <Grid xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAllEvents}
              onChange={handleCheckboxSelectAllTrainings}
              color="secondary"
            />
          }
          label={<Translate id="task-management.all-trainings" />}
          disabled={eventId > 0}
        />
        {!selectAllEvents && (
          <MultiSelect
            options={trainings}
            handleSelection={handleEventChange}
            label={<Translate id="task-management.select-training" />}
            selectedValues={selectedTrainings}
            error={taskGroup?.error_eventIds}
            helperText={
              taskGroup?.error_eventIds && (
                <Translate id="task-management.training-validation" />
              )
            }
            limitTags={5}
            disabled={eventId > 0}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAllPlayers}
              onChange={handleCheckboxSelectAllPlayers}
              color="secondary"
            />
          }
          label={<Translate id="task-management.visible-to-all" />}
        />
        {!selectAllPlayers && (
          <MultiSelect
            options={playersList}
            handleSelection={handlePlayerChange}
            label={<Translate id="task-management.select-player" />}
            selectedValues={selectedPlayers}
            error={taskGroup?.error_modelIds}
            helperText={
              taskGroup?.error_modelIds && (
                <Translate id="task-management.player-validation" />
              )
            }
          />
        )}

        <Input
          label={<Translate id="articles.title" />}
          margin="dense"
          onChange={handleInput}
          name="title"
          value={taskGroup?.title}
          error={taskGroup?.error_title}
          helperText={
            taskGroup?.error_title && (
              <Translate id="task-management.title-validation" />
            )
          }
          required
        />

        <Input
          label={<Translate id="drills.description" />}
          margin="dense"
          onChange={handleInput}
          name="description"
          value={taskGroup.description}
        />
        <SelectedMenu
          id="due-date"
          name="dueDateOffset"
          value={taskGroup.dueDateOffset}
          margin="dense"
          onChange={handleInput}
          label={<Translate id="task-management.due-date" />}
        >
          {TASK_GROUP_DUE_DATES.map((p) => (
            <MenuItem key={p.value} value={p.value}>
              {p.title}
            </MenuItem>
          ))}
        </SelectedMenu>
      </Grid>
      {hasAddTaskQuestionsEnabled ? (
        <AddEditQuestions
          questions={questions}
          handleQuestion={handleQuestion}
          addNewQuestion={addNewQuestion}
          setQuestions={setQuestions}
          taskGroup={taskGroup}
          setTaskGroup={setTaskGroup}
        />
      ) : (
        <TeaserTagOverlay tagLabel={PRO} isSubscriptionPageRedirection={false}>
          <AddEditQuestions
            questions={questions}
            handleQuestion={handleQuestion}
            addNewQuestion={addNewQuestion}
            setQuestions={setQuestions}
            taskGroup={taskGroup}
            setTaskGroup={setTaskGroup}
          />
        </TeaserTagOverlay>
      )}

      <Button
        onClick={
          !isEditMode
            ? () => handleCreate(TRAININGS_GAME.training)
            : () => handleUpdate(TRAININGS_GAME.training, taskGroup.id)
        }
        color="primary"
        variant="contained"
        style={{ marginTop: '1.5rem' }}
        fullWidth
        disabled={
          taskGroup?.error_title ||
          taskGroup?.error_modelIds ||
          taskGroup?.error_eventIds
        }
      >
        {!isEditMode ? (
          <Translate id="button.add" />
        ) : (
          <Translate id="button.update" />
        )}
      </Button>
    </Grid>
  )
}

AddEditTrainingTaskGroup.defaultProps = {
  eventId: 0,
}

AddEditTrainingTaskGroup.propTypes = {
  taskGroup: PROP_TYPES.shape().isRequired,
  isEditMode: PROP_TYPES.bool.isRequired,
  handleInput: PROP_TYPES.func.isRequired,
  handlePlayerChange: PROP_TYPES.func.isRequired,
  handleCreate: PROP_TYPES.func.isRequired,
  handleUpdate: PROP_TYPES.func.isRequired,
  playersList: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  questions: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  handleQuestion: PROP_TYPES.func.isRequired,
  addNewQuestion: PROP_TYPES.func.isRequired,
  setQuestions: PROP_TYPES.func.isRequired,
  setTaskGroup: PROP_TYPES.func.isRequired,
  handleCheckboxSelectAllPlayers: PROP_TYPES.func.isRequired,
  selectAllPlayers: PROP_TYPES.bool.isRequired,
  match: PROP_TYPES.match.isRequired,
  fetchCalendarEvents: PROP_TYPES.func.isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
  eventId: PROP_TYPES.number,
}

export default compose(
  withRouter,
  connect(
    ({ taskGroups, team, auth }) => ({
      isLoading: taskGroups.isLoading,
      team,
      dateFormat: auth.user.datetime_format,
    }),
    {
      fetchCalendarEvents: eventsModule.fetchCalendarEvents,
    }
  )
)(AddEditTrainingTaskGroup)
