import { Paper, FormControl, InputLabel, Select } from '@material-ui/core'
import React from 'react'
import { styled } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import PropTypes from 'prop-types'

const MenuPaper = styled(Paper)({
  border: `1px solid ${grey[300]}`,
  boxShadow: `1px 1px 12px 0 ${grey[400]}`,
})

const SelectedMenu = ({
  value,
  onChange,
  id,
  label,
  variant = 'outlined',
  children,
  ...rest
}) => {
  return (
    <FormControl variant={variant} {...rest}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={onChange}
        label={label}
        MenuProps={{
          PaperProps: {
            component: MenuPaper,
            elevation: 0
          },
        }}
        {...rest}
      >
        {children}
      </Select>
    </FormControl>
  )
}

SelectedMenu.defaultProps = {
  variant: 'outlined',
  children: null,
}

SelectedMenu.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape(),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variant: PropTypes.string,
  children: PropTypes.node,
}

export default SelectedMenu
