import React from 'react'
import PropTypes from 'prop-types'
import { Radio, FormLabel, FormHelperText, Box } from '@material-ui/core'

const RadioGroup = ({ input, meta, label, options, ...rest }) => {
  const error = meta.touched && meta.error

  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...rest}>
      <FormLabel component={Box} mb={0}>
        {label} {label && ':'}
      </FormLabel>

      {options.map(({ value, title }) => (
        <Box display="flex" key={value} flexWrap="wrap" alignItems="center">
          <Radio
            value={value}
            checked={value === input.value}
            onChange={() => input.onChange(value)}
          />{' '}
          {title}
        </Box>
      ))}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}
RadioGroup.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      title: PropTypes.string,
    }).isRequired
  ),
}

export default RadioGroup