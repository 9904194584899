import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import MultiSelect from 'components/material/MultiSelect'
import { DatePicker, Input, SelectedMenu } from 'components'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { diffDate, formatDateForServer } from 'utils/formatDate'
import TeaserTagOverlay from 'components/Teaser/TeaserTagOverlay'
import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import { PRO } from '../../constants/plans'
import { TASK_SERIES_DUE_DATES } from '../../constants/taskGroups'
import AddEditQuestions from './AddEditQuestions'
import AddEditSeries from './AddEditSeries'
import PROP_TYPES from 'constants/propTypes'

const AddEditGeneralTaskGroup = ({
  taskGroup,
  isEditMode,
  playersList,
  handleInput,
  handlePlayerChange,
  handleCreate,
  handleUpdate,
  questions,
  handleQuestion,
  addNewQuestion,
  setQuestions,
  setTaskGroup,
  handleCheckboxSelectAllPlayers,
  selectAllPlayers,
  taskSeries,
  dateFormat,
  handleTaskSeriesCheckBox,
  handleSeriesChange,
}) => {
  const [selectedPlayers, setSelectedPlayer] = useState([])
  const [dueDate, setDueDate] = useState()

  const hasAddTaskQuestionsEnabled = useSubscriptionFeature(
    'add_task_questions_enabled'
  )

  const handleDateChange = (selectedDate) => {
    const selected = formatDateForServer(selectedDate)
    let dueDateOffset = 0

    if (taskGroup.createdAt) {
      const createdAt = formatDateForServer(taskGroup.createdAt)
      dueDateOffset = diffDate(selected, createdAt)
    } else {
      const today = formatDateForServer(new Date())
      dueDateOffset = diffDate(selected, today)
    }
    setTaskGroup({
      ...taskGroup,
      dueDateOffset,
    })
    setDueDate(selectedDate)
  }
  useEffect(() => {
    setSelectedPlayer(
      playersList.filter((player) => taskGroup?.modelIds?.includes(player.id))
    )
    if (taskGroup.createdAt) {
      setDueDate(taskGroup?.events[0]?.due_date)
    }
  }, [taskGroup.modelIds])

  return (
    <Grid container alignItems="center">
      <Grid xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAllPlayers}
              onChange={handleCheckboxSelectAllPlayers}
              color="secondary"
            />
          }
          label={<Translate id="task-management.visible-to-all" />}
        />
        {!selectAllPlayers && (
          <MultiSelect
            options={playersList}
            handleSelection={handlePlayerChange}
            label={<Translate id="task-management.select-player" />}
            selectedValues={selectedPlayers}
            error={taskGroup?.error_modelIds}
            helperText={
              taskGroup?.error_modelIds && (
                <Translate id="task-management.player-validation" />
              )
            }
          />
        )}
        <Input
          label={<Translate id="articles.title" />}
          margin="dense"
          onChange={handleInput}
          name="title"
          value={taskGroup?.title}
          error={taskGroup?.error_title}
          helperText={
            taskGroup?.error_title && (
              <Translate id="task-management.title-validation" />
            )
          }
          required
        />
        <Input
          label={<Translate id="drills.description" />}
          margin="dense"
          onChange={handleInput}
          name="description"
          value={taskGroup.description}
        />
        <FormControl
          component="fieldset"
          margin="dense"
          style={{ display: 'none' }}
        >
          <RadioGroup row name="NumberOfTask">
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label="Single task"
            />
            <FormControlLabel
              value={0}
              control={<Radio color="primary" />}
              label="Task series"
            />
          </RadioGroup>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={taskSeries.isTaskSeries}
              onChange={handleTaskSeriesCheckBox}
              disabled={isEditMode}
              color="secondary"
            />
          }
          label={<Translate id="task-management.task-series" />}
        />
        {taskSeries.isTaskSeries && (
          <AddEditSeries
            dateFormat={dateFormat}
            taskSeries={taskSeries}
            handleSeriesChange={handleSeriesChange}
            isEditMode={isEditMode}
          />
        )}

        {taskSeries.isTaskSeries ? (
          <SelectedMenu
            id="due-date"
            name="dueDateOffset"
            value={taskGroup.dueDateOffset}
            margin="dense"
            onChange={handleInput}
            label={<Translate id="task-management.due-date" />}
          >
            {TASK_SERIES_DUE_DATES.map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {p.title}
              </MenuItem>
            ))}
          </SelectedMenu>
        ) : (
          <DatePicker
            required
            label={<Translate id="task-management.due-date" />}
            dateFormat={dateFormat}
            selectedValue={dueDate}
            onDateChange={handleDateChange}
            isEditMode={!!dueDate}
          />
        )}
      </Grid>
      {hasAddTaskQuestionsEnabled ? (
        <AddEditQuestions
          questions={questions}
          handleQuestion={handleQuestion}
          addNewQuestion={addNewQuestion}
          setQuestions={setQuestions}
          taskGroup={taskGroup}
          setTaskGroup={setTaskGroup}
        />
      ) : (
        <TeaserTagOverlay tagLabel={PRO} isSubscriptionPageRedirection={false}>
          <AddEditQuestions
            questions={questions}
            handleQuestion={handleQuestion}
            addNewQuestion={addNewQuestion}
            setQuestions={setQuestions}
            taskGroup={taskGroup}
            setTaskGroup={setTaskGroup}
          />
        </TeaserTagOverlay>
      )}
      <Button
        onClick={
          !isEditMode
            ? () => handleCreate(null)
            : () => handleUpdate(null, taskGroup.id)
        }
        color="primary"
        variant="contained"
        style={{ marginTop: '1.5rem' }}
        fullWidth
        disabled={
          taskGroup?.error_title ||
          taskGroup?.error_modelIds ||
          taskSeries?.dateError
        }
      >
        {!isEditMode ? (
          <Translate id="button.add" />
        ) : (
          <Translate id="button.update" />
        )}
      </Button>
    </Grid>
  )
}
AddEditGeneralTaskGroup.propTypes = {
  taskGroup: PROP_TYPES.shape().isRequired,
  isEditMode: PROP_TYPES.bool.isRequired,
  handleInput: PROP_TYPES.func.isRequired,
  handlePlayerChange: PROP_TYPES.func.isRequired,
  handleCreate: PROP_TYPES.func.isRequired,
  handleUpdate: PROP_TYPES.func.isRequired,
  playersList: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  questions: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  handleQuestion: PROP_TYPES.func.isRequired,
  addNewQuestion: PROP_TYPES.func.isRequired,
  setQuestions: PROP_TYPES.func.isRequired,
  setTaskGroup: PROP_TYPES.func.isRequired,
  handleCheckboxSelectAllPlayers: PROP_TYPES.func.isRequired,

  selectAllPlayers: PROP_TYPES.bool.isRequired,
  handleTaskSeriesCheckBox: PROP_TYPES.func.isRequired,
  taskSeries: PROP_TYPES.shape().isRequired,
  handleSeriesChange: PROP_TYPES.func.isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
}
export default compose(
  withRouter,
  connect(
    ({ auth }) => ({
      dateFormat: auth.user.datetime_format,
    }),
    {}
  )
)(AddEditGeneralTaskGroup)
