import React from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { Field, reduxForm } from 'redux-form'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import { Input, RadioGroup } from 'components'
import createJoiValidator from 'utils/createJoiValidator'
import { Box, Button, Grid, makeStyles } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import CustomAutocomplete from 'components/CustomAutocomplete'

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  },
  radio: {
    '& .MuiFormLabel-root': {
      minWidth: 90,
      marginLeft: theme.spacing(0.8),
    },
    '& .MuiRadio-root': {
      padding: theme.spacing(0.4, 0.8),
    },
  },
  errorOnBottom: {
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      bottom: '-20px',
      marginLeft: '14px',
    },
  },
}))

const GENDER_OPTIONS = [
  {
    value: 'm',
    title: 'Male',
  },
  {
    value: 'f',
    title: 'Female',
  },
]
const validGenderValue = GENDER_OPTIONS.map((option) => option.value)
const validationSchema = Joi.object().keys({
  name: Joi.string().max(255).required(),
  age_class_uuid: Joi.string().required(),
  gender: Joi.string()
    .valid(...validGenderValue)
    .required(),
  tag: Joi.any(),
})

const CreateTeamForm = ({
  open,
  onClose,
  handleSubmit,
  ageClasses,
  selectedClub,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle id="form-dialog-title">
        <Translate id="myteams.create-team" />
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="name"
                label={<Translate id="myteams.team-name" />}
                component={Input}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.label}>
                <FormControl className={classes.errorOnBottom}>
                  <Field
                    name="age_class_uuid"
                    label={<Translate id="team.age-class" />}
                    component={RadioGroup}
                    className={classes.radio}
                    options={(ageClasses || []).map((ageClass) => ({
                      value: ageClass.uuid,
                      title: ageClass.name,
                    }))}
                    required
                  />
                </FormControl>
              </Box>
            </Grid>
            {selectedClub && selectedClub.enable_team_tags && (
              <Grid item xs={12}>
                <Field
                  name="tag"
                  label={<Translate id="team.tag" />}
                  component={CustomAutocomplete}
                  options={(selectedClub?.relationships?.team_tags || []).map(
                    (tag) => ({ title: tag.name })
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Field
                name="gender"
                label={<Translate id="team.gender" />}
                component={RadioGroup}
                className={classes.radio}
                options={GENDER_OPTIONS}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <Translate id="button.cancel" />
          </Button>
          <Button type="submit" color="primary">
            <Translate id="button.save" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CreateTeamForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  ageClasses: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedClub: PropTypes.shape({
    relationships: PropTypes.shape({
      team_tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    enable_team_tags: PropTypes.bool.isRequired,
  }),
}

export default reduxForm({
  form: 'createTeamForm',
  validate: createJoiValidator(validationSchema),
})(CreateTeamForm)
