import { Box, Button, Grid, MenuItem, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Input, LoadingSpinner, SelectedMenu } from 'components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Translate } from 'react-localize-redux'
import useStyles from '../styles'
import { sendInviteUrl } from '../api'
import propTypes from 'constants/propTypes'

const Staff = ({ team, currentClubId }) => {
  const classes = useStyles()

  const [roleUuid, setRoleUuid] = useState('')
  const [email, setEmail] = useState('')
  const [functionValue, setFunctionValue] = useState('')
  const [roleError, setRoleError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [functionError, setFunctionError] = useState('')
  const [isLoading, setLoading] = useState(false)

  const handleReset = () => {
    setRoleUuid('')
    setEmail('')
    setFunctionValue('')
    setRoleError('')
    setEmailError('')
    setFunctionError('')
  }

  const validateInputs = () => {
    let isValid = true
    if (!roleUuid) {
      setRoleError(<Translate id="team.members.role-required" />)
      isValid = false
    } else {
      setRoleError('')
    }

    if (!email) {
      setEmailError(<Translate id="team.members.email-required" />)
      isValid = false
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError(<Translate id="team.members.invalid-email-format" />)
      isValid = false
    } else {
      setEmailError('')
    }

    if (!functionValue) {
      setFunctionError(<Translate id="team.members.function-required" />)
      isValid = false
    } else {
      setFunctionError('')
    }

    return isValid
  }

  const handleSendInvite = () => {
    if (!validateInputs()) {
      toast.error('Please fill the highlighted fields')

      return
    }

    const data = {
      role_uuid: roleUuid,
      function: functionValue,
      email,
    }

    setLoading(true)
    sendInviteUrl(team.uuid, data)
      .then(() => {
        handleReset()
        toast.success('Invite sent successfully')
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Network error. Please try again later')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const allowedStaffRoles = JSON.parse(
    process.env.REACT_APP_ALLOWED_STAFF_ROLES
  )
  const cfgClubId = +process.env.REACT_APP_CFG_CLUB_ID

  const filteredRoles = allowedStaffRoles.filter(
    (role) => role.name !== 'ground_coach' || currentClubId === cfgClubId
  )

  const formatRoleName = (name) => {
    return _.startCase(_.toLower(name))
  }

  return (
    <>
      <Box p={2.5}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <>
            <Typography variant="body2" className={classes.title}>
              <Translate id="team.members.invite-details" />
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <SelectedMenu
                  id="role"
                  margin="dense"
                  value={roleUuid}
                  onChange={(e) => setRoleUuid(e.target.value)}
                  label={<Translate id="team.members.select-role" />}
                  className={classes.menu}
                  required
                >
                  {filteredRoles.map((role) => (
                    <MenuItem key={role.uuid} value={role.uuid}>
                      {formatRoleName(role.name)}
                    </MenuItem>
                  ))}
                </SelectedMenu>
                <Box height={2.4}>
                  {roleError && (
                    <Typography variant="body2" color="error">
                      {roleError}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Input
                  label={<Translate id="userRoles.e-mail" />}
                  margin="dense"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  value={email}
                  required
                  className={classes.email}
                />
                <Box height={2.4}>
                  {emailError && (
                    <Typography variant="body2" color="error">
                      {emailError}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Input
                  label={<Translate id="userRoles.function" />}
                  margin="dense"
                  onChange={(e) => setFunctionValue(e.target.value)}
                  name="function"
                  value={functionValue}
                  required
                  className={classes.menu}
                />
                <Box height={2.4}>
                  {functionError && (
                    <Typography variant="body2" color="error">
                      {functionError}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="end" p={2.5}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleReset}
          className={classes.resetBtn}
          disabled={isLoading}
        >
          <Translate id="button.reset" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendInvite}
          disabled={isLoading}
        >
          <Translate id="team.members.send-invite" />
        </Button>
      </Box>
    </>
  )
}

Staff.propTypes = {
  team: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  currentClubId: propTypes.string.isRequired,
}

export default connect(({ team, clubs }) => ({
  team,
  currentClubId: clubs.current.id,
}))(Staff)
