import { getRequest, deleteRequest, postRequest } from 'utils/axios'

export const fetchAllMembers = (teamUuid, filterParams = {}) => {
  const url = `v2/teams/${teamUuid}/members`

  return getRequest(url, filterParams)
}

export const removeUserRole = (uhrUuid) => {
  const url = `v2/remove-member-role/${uhrUuid}`

  return deleteRequest(url)
}

export const sendInviteUrl = (teamUuid, data) => {
  const url = `v2/teams/${teamUuid}/invite-staff`

  return postRequest(url, data)
}
