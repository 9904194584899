import React, { useState } from 'react'
import { connect } from 'react-redux'
import { players } from 'redux/modules'
import { withStyles, styled } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import {
  Paper,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  Box,
  Divider,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Toolbar,
} from '@material-ui/core'
import binIcon from 'assets/images/16px/bin@1x.svg'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import DescriptionIcon from '@material-ui/icons/Description'
import HealingOutlinedIcon from '@material-ui/icons/HealingOutlined'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import HeightIcon from '@material-ui/icons/Height'
import StraightenIcon from '@material-ui/icons/Straighten'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import editIcon from 'assets/images/16px/edit@1x.svg'
import AlertDialog from 'components/AlertDialog'
import { formatCostumDateForUI } from 'utils/formatDate'
import TabPanel from 'components/material/TabPanel'
import AddEditPlayerFitnessMedical from './AddEditPlayerFitnessMedical'
import OtherDocuments from './OtherDocuments'
import OtherDocumentUploadDialog from './OtherDocumentUploadDialog'
import { useStyles } from './styles'

const MuiBox = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    height: '5vh',
    '& .MuiBox-root': {
      minWidth: 40,
      maxWidth: 180,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiTypography-root': {
        padding: theme.spacing(0, 2),
      },
    },
  },
}))(Box)

const CustomButtonIcon = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    background: '#f1f1f1',
    borderRadius: '0.5rem',
    marginRight: theme.spacing(1),
  },
}))

const PlayerFitnessMedical = ({
  playerId,
  fitnessNmedicalRecords,
  createPlayerPhysique,
  updatePlayerPhysique,
  deletePlayerPhysique,
  createPlayerInjury,
  updatePlayerInjury,
  deletePlayerInjury,
  createPlayerNote,
  updatePlayerNote,
  deletePlayerNote,
  physicalAge,
  physicalHeight,
  physicalWeight,
  fetchTeamPlayer,
  dateFormat,
}) => {
  const classes = useStyles()
  const [addEditFitnessRecordDialogOpen, setAddEditFitnessRecordDialogOpen] =
    useState(false)
  const [selectedValue, setSelectedValue] = useState()
  const [selectedTabValue, setSelectedTabValue] = useState(0)
  const [existingData, setExistingData] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [recordToDelete, setRecordToDelete] = useState(null)
  const [isAddedOrDeletedDocument, setIsAddedOrDeletedDocument] =
    useState(false)
  const [tabValue, setTabValue] = useState('fitness-and-medical')
  const [documentUploadDialogOpen, setOtherDocumentUploadDialogOpen] =
    useState(false)

  const handleClickOpen = (type, recId = null) => {
    setSelectedTabValue(type)
    setExistingData(
      fitnessNmedicalRecords.filter((rec) => rec.unique_id === recId)
    )
    setAddEditFitnessRecordDialogOpen(true)
  }

  const handleOpenAlert = (record) => {
    setRecordToDelete(record)
    setOpenAlert(true)
  }

  const handleCloseAlert = () => {
    setRecordToDelete(null)
    setOpenAlert(false)
  }

  const updatePlayerAfter = (tempPlayerId) => {
    fetchTeamPlayer(tempPlayerId, {
      fields: 'avatar,sequences,statistics,journal,last_match,logo',
    })
  }

  const handleDeleteRecord = (id) => {
    deletePlayerPhysique(id).then(updatePlayerAfter(playerId))
  }

  const handleDeleteInjuryRecord = (id) => {
    deletePlayerInjury(id).then(updatePlayerAfter(playerId))
  }

  const handleDeleteNoteRecord = (id) => {
    deletePlayerNote(id).then(updatePlayerAfter(playerId))
  }

  const handleAlertConfirm = () => {
    if (recordToDelete) {
      const recordId = recordToDelete.id
      const recordType = recordToDelete.type

      if (recordType === 'physique') {
        handleDeleteRecord(recordId)
      } else if (recordType === 'injury') {
        handleDeleteInjuryRecord(recordId)
      } else if (recordType === 'note') {
        handleDeleteNoteRecord(recordId)
      }

      handleCloseAlert()
    }
  }

  const handleClose = (val) => {
    setAddEditFitnessRecordDialogOpen(false)
    setSelectedValue(val)
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const toggleOtherDocumentUploadDialog = () => {
    setOtherDocumentUploadDialogOpen(!documentUploadDialogOpen)
  }

  return (
    <>
      {documentUploadDialogOpen && (
        <OtherDocumentUploadDialog
          open={documentUploadDialogOpen}
          hanldeOpenClose={toggleOtherDocumentUploadDialog}
          setIsAddedOrDeletedDocument={setIsAddedOrDeletedDocument}
        />
      )}
      {openAlert && (
        <AlertDialog
          open={openAlert}
          onClose={handleCloseAlert}
          onSuccess={handleAlertConfirm}
          onCancel={handleCloseAlert}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.delete" />}
          cancelButtonLabel={<Translate id="button.cancel" />}
        />
      )}
      {addEditFitnessRecordDialogOpen && (
        <AddEditPlayerFitnessMedical
          playerId={playerId}
          createPlayerPhysique={createPlayerPhysique}
          updatePlayerPhysique={updatePlayerPhysique}
          createPlayerInjury={createPlayerInjury}
          updatePlayerInjury={updatePlayerInjury}
          createPlayerNote={createPlayerNote}
          updatePlayerNote={updatePlayerNote}
          fetchTeamPlayer={updatePlayerAfter}
          selectedValue={selectedValue}
          selectedTabValue={selectedTabValue}
          existingData={existingData.length ? existingData[0] : null}
          open={addEditFitnessRecordDialogOpen}
          onClose={handleClose}
        />
      )}

      <Paper>
        <Toolbar disableGutters="false" variant="dense">
          <Box className={classes.title} style={{ flexGrow: 1 }}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab
                label={<Translate id="team.player-fitness-and-medical" />}
                value="fitness-and-medical"
              />
              <Tab
                label={<Translate id="team.other-documents" />}
                value="other-documents"
              />
            </Tabs>
          </Box>
          <Box mx={2}>
            {tabValue === 'fitness-and-medical' ? (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => handleClickOpen(null, null)}
              >
                <Translate id="team.add-record" />
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={toggleOtherDocumentUploadDialog}
              >
                <Translate id="team.upload" />
              </Button>
            )}
          </Box>
        </Toolbar>
        <Divider />
        <TabPanel
          className={classes.minHeight}
          value={tabValue}
          index="fitness-and-medical"
          padding={0}
        >
          {fitnessNmedicalRecords.length > 0 ? (
            <Grid container className={classes.paddingDiv}>
              <Grid item xs={12}>
                <MuiBox>
                  <Box>
                    <CalendarTodayIcon color="disabled" size="small" />
                    <Typography variant="h4">
                      {physicalAge}
                      &nbsp;
                      <Translate id="player.yrs" />
                    </Typography>
                  </Box>
                  <Box>
                    <HeightIcon color="disabled" size="small" />
                    <Typography variant="h4">
                      {physicalHeight}
                      &nbsp;cm
                    </Typography>
                  </Box>
                  <Box>
                    <StraightenIcon color="disabled" size="small" />
                    <Typography variant="h4">
                      {physicalWeight}
                      &nbsp;kg
                    </Typography>
                  </Box>
                </MuiBox>
                <Divider />
                <Box className={classes.fitnessDataBox}>
                  <List>
                    {fitnessNmedicalRecords.map((record, index) => (
                      <ListItem
                        alignItems="center"
                        key={record.id + record.type}
                        id={index}
                        divider
                      >
                        <ListItemAvatar>
                          {record.type === 'physique' && (
                            <AccessibilityIcon color="disabled" size="small" />
                          )}
                          {record.type === 'injury' && (
                            <HealingOutlinedIcon
                              color="disabled"
                              size="small"
                            />
                          )}

                          {record.type === 'note' && (
                            <DescriptionIcon color="disabled" size="small" />
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              variant="body2"
                              className=""
                              color="textPrimary"
                            >
                              {record.type === 'physique' && (
                                <>
                                  <strong>
                                    <Translate id="player.record.body-data" />{' '}
                                    &bull; {record.user}
                                  </strong>
                                  {record.noted_at}
                                  <Box display="flex" alignItems="center">
                                    {formatCostumDateForUI(
                                      record.noted_at,
                                      dateFormat
                                    )}
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      ml={1}
                                    >
                                      <HeightIcon />
                                      {record.height}
                                      cm
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      ml={1}
                                    >
                                      <StraightenIcon /> &nbsp;
                                      {record.weight}
                                      kg
                                    </Box>
                                  </Box>
                                  {record.note}
                                </>
                              )}
                              {record.type === 'injury' && (
                                <>
                                  <strong>
                                    <Translate id="player.record.medical-note" />{' '}
                                    &bull; {record.user}
                                  </strong>
                                  <br />
                                  <Translate id="form.from" />
                                  &nbsp;
                                  {formatCostumDateForUI(
                                    record.from,
                                    dateFormat
                                  )}
                                  &nbsp;
                                  <Translate id="form.to" />
                                  &nbsp;
                                  {formatCostumDateForUI(record.to, dateFormat)}
                                  <br />
                                  {record.note}
                                </>
                              )}

                              {record.type === 'note' && (
                                <>
                                  <strong>
                                    <Translate id="player.record.additional-note" />{' '}
                                    &bull; {record.user}
                                  </strong>
                                  <br />
                                  {formatCostumDateForUI(
                                    record.noted_at,
                                    dateFormat
                                  )}
                                  <br />
                                  {record.note}
                                </>
                              )}
                            </Typography>
                          }
                        />
                        <Tooltip
                          title={<Translate id="button.edit" />}
                          placement="top"
                          arrow
                          onClick={() =>
                            handleClickOpen(record.type, record.unique_id)
                          }
                        >
                          <IconButton>
                            <img src={editIcon} alt={editIcon} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={<Translate id="button.delete" />}
                          placement="top"
                          arrow
                        >
                          <CustomButtonIcon
                            onClick={() => handleOpenAlert(record)}
                          >
                            <img src={binIcon} alt={binIcon} />
                          </CustomButtonIcon>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={classes.minHeight}
            >
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  spacing={3}
                  style={{ textAlign: 'center' }}
                >
                  <IconButton onClick={handleClickOpen} color="primary">
                    <ControlPointIcon />
                  </IconButton>{' '}
                  <Typography variant="h6">
                    <Translate id="team.fitness-empty-note" />
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          )}
        </TabPanel>
        <TabPanel
          value={tabValue}
          index="other-documents"
          className={classes.minHeight}
          padding={0}
        >
          <OtherDocuments
            toggleOtherDocumentUploadDialog={toggleOtherDocumentUploadDialog}
            isAddedOrDeletedDocument={isAddedOrDeletedDocument}
            setIsAddedOrDeletedDocument={setIsAddedOrDeletedDocument}
          />
        </TabPanel>
      </Paper>
    </>
  )
}

PlayerFitnessMedical.defaultProps = {
  fitnessNmedicalRecords: [],
  physicalAge: 0,
  physicalHeight: 0,
  physicalWeight: 0,
}

PlayerFitnessMedical.propTypes = {
  fitnessNmedicalRecords: PropTypes.arrayOf(PropTypes.shape()),
  createPlayerPhysique: PropTypes.func.isRequired,
  updatePlayerPhysique: PropTypes.func.isRequired,
  deletePlayerPhysique: PropTypes.func.isRequired,
  createPlayerInjury: PropTypes.func.isRequired,
  updatePlayerInjury: PropTypes.func.isRequired,
  createPlayerNote: PropTypes.func.isRequired,
  updatePlayerNote: PropTypes.func.isRequired,
  deletePlayerInjury: PropTypes.func.isRequired,
  deletePlayerNote: PropTypes.func.isRequired,
  playerId: PropTypes.number.isRequired,
  physicalAge: PropTypes.number,
  physicalHeight: PropTypes.number,
  physicalWeight: PropTypes.number,
  fetchTeamPlayer: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
}

export default connect(
  ({ auth }) => ({
    dateFormat: auth.user.datetime_format,
  }),
  {
    createPlayerPhysique: players.createPlayerPhysique,
    updatePlayerPhysique: players.updatePlayerPhysique,
    deletePlayerPhysique: players.deletePlayerPhysique,
    createPlayerInjury: players.createPlayerInjury,
    updatePlayerInjury: players.updatePlayerInjury,
    createPlayerNote: players.createPlayerNote,
    updatePlayerNote: players.updatePlayerNote,
    deletePlayerInjury: players.deletePlayerInjury,
    deletePlayerNote: players.deletePlayerNote,
    fetchTeamPlayer: players.fetchTeamPlayer,
  }
)(PlayerFitnessMedical)
