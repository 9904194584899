import React from 'react'
import { Translate } from 'react-localize-redux'
import { Avatar, Box, Card, makeStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import BallIcon from 'assets/images/ballicon.svg'
import { formateDate } from 'utils/formatDate'
import { SubLink } from 'containers'
import { withRouter } from 'react-router-dom'
import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import PlayerEditDialog from './PlayerEditDialog'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  AvatarWrap: {
    width: 140,
    borderRadius: 70,
    margin: theme.spacing(0, 'auto'),
    position: 'relative',
    padding: theme.spacing(0.3),
  },
  AvatarImage: {
    width: 140,
    height: 140,
  },
  AvatarEditIcon: {
    position: 'absolute',
    top: 0,
    right: -60,
  },
  wrapper: {
    height: '100%',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
const StyledRating = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
}))(Rating)

const PlayerSummary = ({
  player,
  updatePlayer,
  updatePlayerAvatar,
  match: { params },
}) => {
  const [open, setOpen] = React.useState(false)

  const hasPlayerLastMatchLinkAndRatingEnabled = useSubscriptionFeature(
    'player_last_match_link_and_rating_enabled'
  )

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { teamId, seasonId } = params
  const {
    first_name: firstName,
    last_name: lastName,
    avatar,
    last_match: lastMatch,
  } = player

  const lastMatchDate =
    lastMatch && lastMatch.date ? formateDate(lastMatch.date) : null

  const classes = useStyles()

  return (
    <Card component={Box} p={3} className={classes.wrapper}>
      {open && (
        <PlayerEditDialog
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          initialValues={player}
          updatePlayer={updatePlayer}
          updatePlayerAvatar={updatePlayerAvatar}
          isPlayerDashboard
        />
      )}
      <Box className={classes.AvatarWrap}>
        <IconButton onClick={handleOpen} className={classes.AvatarEditIcon}>
          <EditIcon fontSize="small" />
        </IconButton>
        <Avatar
          alt="logo"
          src={avatar && avatar.full_url ? avatar.full_url : BallIcon}
          className={classes.AvatarImage}
        />
      </Box>
      <div className={classes.textWrapper}>
        <Typography variant="h4" component="h4">
          {firstName} {lastName}{' '}
        </Typography>
      </div>
      {hasPlayerLastMatchLinkAndRatingEnabled && (
        <SubLink
          to={
            lastMatch && lastMatch.id
              ? `/team/${+teamId}/season/${+seasonId}/matches/${lastMatch.uuid}`
              : 0
          }
          isFullPath
        >
          <div className={classes.textWrapper}>
            <span className="lastplaydate">
              <Translate id="player.last-game" />{' '}
              {lastMatchDate
                ? `${lastMatchDate[4]} ${lastMatchDate[3]} ${lastMatchDate[0]}`
                : 'n/a'}
            </span>

            <StyledRating
              name="simple-controlled"
              value={
                lastMatch && lastMatch.pivot.rating ? lastMatch.pivot.rating : 0
              }
              readOnly
              precision={0.5}
            />
          </div>
        </SubLink>
      )}
    </Card>
  )
}
PlayerSummary.propTypes = {
  player: PROP_TYPES.shape().isRequired,
  updatePlayer: PROP_TYPES.func.isRequired,
  updatePlayerAvatar: PROP_TYPES.func.isRequired,
  match: PROP_TYPES.shape().isRequired,
}

export default withRouter(PlayerSummary)
