import React from 'react'
import HttpsIcon from '@material-ui/icons/Https'
import { SubLink } from 'containers'
import { makeStyles } from '@material-ui/core'
import useSubscriptionFeature from 'hooks/useSubcriptionFeature'
import {
  Teaser,
  TeaserLabel,
  TeaserIcon,
  Text,
  TeaserBox,
} from './styledComponents'
import PROP_TYPES from 'constants/propTypes'
import { TEAM_SUBSCRIPTION_UPGRADE_ROUTE } from 'constants/routes'
import { PRO } from 'constants/plans'

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  disableLink: {
    pointerEvents: 'none',
  },
}))

const TeaserTagOverlay = ({
  tagLabel,
  icon,
  isLabelTag,
  children,
  wrapperStyleObj,
  labelStyleObj,
  isSubscriptionPageRedirection,
}) => {
  const classes = useStyles()

  // show_feature_plus_subscription and show_feature_pro_subscription used to show hide the feature in other clubs
  // If either of the value is false. It means the user team is related with other clubs
  const isClubSolution =
    !useSubscriptionFeature('show_feature_plus_subscription') ||
    !useSubscriptionFeature('show_feature_pro_subscription')

  // Hide the feature on Club Solution without minimal subscription tier
  if (isClubSolution) {
    return null
  }

  // Show teaser for Default Club without minimal subscription tier
  return (
    <TeaserBox style={wrapperStyleObj}>
      <>
        <Teaser>
          <SubLink
            to={TEAM_SUBSCRIPTION_UPGRADE_ROUTE}
            className={
              !isSubscriptionPageRedirection
                ? classes.disableLink
                : classes.fullWidth
            }
            fullWidth
          >
            {isLabelTag && (
              <TeaserLabel style={labelStyleObj}>
                {/* @todo We can remove this when we will get 'pro' instead 'elite' in team subscription */}
                <Text>{tagLabel === PRO ? 'PRO' : tagLabel}</Text>
              </TeaserLabel>
            )}
            {icon && (
              <TeaserIcon>
                <HttpsIcon fontSize="small" />
              </TeaserIcon>
            )}
          </SubLink>
        </Teaser>
        {children}
      </>
    </TeaserBox>
  )
}

TeaserTagOverlay.defaultProps = {
  icon: false,
  isLabelTag: true,
  wrapperStyleObj: {},
  labelStyleObj: {},
  isSubscriptionPageRedirection: true,
}

TeaserTagOverlay.propTypes = {
  tagLabel: PROP_TYPES.string.isRequired,
  children: PROP_TYPES.children,
  icon: PROP_TYPES.bool,
  isLabelTag: PROP_TYPES.bool,
  wrapperStyleObj: PROP_TYPES.shape(),
  labelStyleObj: PROP_TYPES.shape(),
  isSubscriptionPageRedirection: PROP_TYPES.bool,
}

export default TeaserTagOverlay
