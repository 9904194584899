import { useSelector } from 'react-redux';

const useSubscriptionFeature = (featureName) => {
  // Access the team's features from the Redux store
  const features = useSelector((state) => state.team.features);

  // Check if the feature exists and is enabled
  const hasFeature = features?.[featureName] === true;

  return hasFeature;
};

export default useSubscriptionFeature;
