import { Box, Paper, Tabs, Tab, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import useStyles from '../styles'
import Staff from './Staff'
import Player from './Player'

const InviteMembers = () => {
  const classes = useStyles()
  const [value, setValue] = useState('player')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box component={Paper} className={classes.invites}>
        <Typography variant="h4" className={classes.invitesTitle}>
          <Translate id="team.members.invite-new" />
        </Typography>
        <Box className={classes.tabContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={<Translate id="events.table-player" />}
              value="player"
              className={classes.tab}
            />
            <Tab
              label={<Translate id="team.staff" />}
              value="staff"
              className={classes.tab}
            />
          </Tabs>
        </Box>
        <Box className={classes.contentContainer}>
          {value === 'player' && <Player />}
          {value === 'staff' && <Staff />}
        </Box>
      </Box>
    </>
  )
}

export default InviteMembers
