import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { track } from 'utils/segmentTracking'
import { Snackbars as Snackbar } from 'components'
import PropTypes from 'prop-types'
import { teams as teamsModule } from 'redux/modules'
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined'
import useStyles from '../styles'
import { team as teamEvent } from 'constants/trackeventsname'

const Player = ({ team, fetchInviteUrl }) => {
  const classes = useStyles()
  const [deepLink, setDeepLink] = useState('Loading...')
  const [snackbar, setSnackbar] = useState(false)

  useEffect(() => {
    fetchInviteUrl(team.uuid).then(({ response: { data } }) => {
      setDeepLink(data.url)
    })
  }, [])

  const handleLinkClick = (event) => {
    event.preventDefault()
    navigator.clipboard.writeText(deepLink)

    setSnackbar({
      message: <Translate id="team-invite.link-copied" />,
      success: true,
    })

    track(teamEvent.team_players_invite_link_copy, {
      team_id: +team.id,
      category: teamEvent.team,
    })
  }

  return (
    <>
      <Box p={2.5}>
        <Grid xs={12}>
          <Typography
            variant="h2"
            component="h2"
            className={classes.invitationLink}
          >
            <Translate id="team-invite.send-link-title" />
          </Typography>
          <Typography variant="h6" component="h6" paragraph>
            <Translate id="team-invite.send-link-subtitle" />
            <Typography variant="strong" component="strong">
              &quot;{team.name}&quot;
            </Typography>
          </Typography>
          <Box
            width="100%"
            mb={1}
            display="flex"
            alignItems="center"
            onClick={handleLinkClick}
            role="button"
          >
            <a href={deepLink} className={classes.deepLink}>
              {deepLink}
              <FilterNoneOutlinedIcon
                fontSize="inherit"
                className={classes.copyIcon}
              />
            </a>
          </Box>
        </Grid>
      </Box>
      {snackbar && <Snackbar {...snackbar} />}
    </>
  )
}

Player.propTypes = {
  team: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  fetchInviteUrl: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ team }) => ({
      team,
    }),
    {
      fetchInviteUrl: teamsModule.fetchInviteUrl,
    }
  )
)(Player)
