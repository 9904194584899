import React from 'react'
import PropTypes from 'prop-types'
import requiredIf from 'react-required-if'
import { withRouter } from 'react-router-dom'
import urlConstructor from 'utils/urlConstructor'
import { StyledLink } from '../styledComponents'

const NavLink = ({
  children,
  className,
  to,
  fromPathname,
  isFullPath,
  match,
  target,
  onClick,
  ...restProps
}) => {
  const {
    params: { teamId, seasonId },
    url: fromUrl,
  } = match

  const pathname = isFullPath ? to : urlConstructor(teamId, seasonId, to)

  return (
    <StyledLink
      className={className}
      to={({
        pathname,
          fromUrl,
          fromPathname,
        ...restProps
      })}
      onClick={onClick}
      target={target}
    >
      {children}
    </StyledLink>
  )
}

NavLink.defaultProps = {
  children: <div />,
  className: null,
  to: '',
  target: '',
  fromPathname: undefined,
  isFullPath: false,
  isExternal: false,
}

NavLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  fromPathname: PropTypes.string,
  target: PropTypes.string,
  isFullPath: PropTypes.bool,
  isExternal: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
  }),
  onClick: requiredIf(PropTypes.func, props => !props.to),
}

export default withRouter(NavLink)
